class Modals{

    constructor(){
    
        $ = jQuery;

        $('.share-input').on( 'click', function(){
            this.setSelectionRange(0, this.value.length);
        });

        $('#copy-share-input').on( 'click', () => {
            if ( this.copyToClipboard( $('.share-input').get()[0] ) ) {
                $('#copy-share-input').html( '<i class="fas fa-check mr-2"></i>Link Copied' )
            }
        });
    
    }

    copyToClipboard( elem ) {

        var targetId = "_hiddenCopyText_";
        var origSelectionStart, origSelectionEnd;

        // can just use the original source element for the selection and copy
        var target = elem;
        origSelectionStart = elem.selectionStart;
        origSelectionEnd = elem.selectionEnd;

        // select the content
        var currentFocus = document.activeElement;
        target.focus();
        target.setSelectionRange(0, target.value.length);
        
        // copy the selection
        var succeed;
        try {
            succeed = document.execCommand("copy");
        } catch(e) {
            succeed = false;
        }
        // restore original focus
        if (currentFocus && typeof currentFocus.focus === "function") {
            currentFocus.focus();
        }
        
        return succeed;
    }

}

jQuery(document).ready( () => new Modals() );