class Menu{

    constructor(){

        $ = jQuery;
        let $menu = $( '#menu-header-menu' );

        //Mobile menu Listener
        $( '#mobile-menu-trigger' ).on( 'click', function(){
            $menu.fadeToggle();
            $(this).toggleClass( 'fa-bars' ).toggleClass( 'fa-times' );
        });

    }

}

jQuery(document).ready( () => new Menu() );