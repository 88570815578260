ThemeJS.VideoPlayer = ( function( $ ){

    //Listeners
    $( document ).on( 'click', '.video-container .controls', function(){
        var $video = $( this ).closest( '.video-container' ).find( 'video' );
        $( this ).fadeOut(function(){
            $video.get(0).play();
            $video.attr('controls', true);
        });
    });

})( jQuery );