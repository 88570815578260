class Sliders {

    constructor () {
        
        const testimonial_options = {
            infinite: true,
            dots: false,
            arrows: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"><i class="fas fa-chevron-right"></i></button>',
        };

        const blog_options = {
            infinite: false,
            dots: false,
            arrows: true,
            autoplay: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="display: block;"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;"><i class="fas fa-chevron-right"></i></button>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        };

        jQuery( '.slick-slider' ).each( function () {
            let options = $(this).hasClass( 'blog-slider' ) ? blog_options : testimonial_options;
            $(this).slick( options );
        });
    }

}

jQuery(document).ready( () => new Sliders() );