ThemeJS.Tabs = (function ( $, root ) {

    function init_block ( tab_block ) {
        let $tab_nav;
        let $tab_links;
        let $tab_windows;

        function set_global_element_vars () {
            $tab_nav = $('div.tab-nav', tab_block );
            $tab_links = $('a.tab-nav-link', tab_block );
            $tab_windows = $('div.tab-data-window', tab_block );
        }

        function set_nav_listeners () {
            let trigger = $tab_nav.data().trigger;
            $tab_links.on( trigger, function ( e ) {
                e.preventDefault();
                if ( $(this).hasClass( 'active' ) ) { return; }
                let active_key = $(this).data().key;
                $tab_links.add( $tab_windows ).each(function () {
                    $(this).toggleClass( 'active', $(this).data().key == active_key );
                });
                $(document).trigger( 'tab-change' );
            });
        }

        function resize_triangles () {

        }

        set_global_element_vars();
        set_nav_listeners();
        //$(window).resize( resize_triangles );
    }

    function init () {
        $('.tab-block').each( function () {
            init_block( this );
        });
    }

    $(function () {
        init();
    });

    return {
        init
    }

})( jQuery, this );